import React from "react";
import { Box } from "@mui/material";
import instituto360 from "../../assets/Instituto360.png";
import Clima from "../../assets/TempoClima.png";

function MapIframeBox() {
  return (
    <Box
      sx={{
        maxWidth: "350px",
        marginBottom: "25px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid black",
        borderRadius: "20px",
      }}
    >
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11377.583245548118!2d-44.7958801!3d-22.3526094!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7c2ad97e0c3fff67!2sInstituto%20Alto%20Montana%20da%20Serra%20Fina!5e1!3m2!1spt-BR!2sbr!4v1667165357248!5m2!1spt-BR!2sbr"
        style={{
          border: 0,
          borderRadius: "16px",
          margin: "40px",
          height: "30vh",
        }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
      <Box
        sx={{
          display: "flex",
          gap: "25px",
          margin: "30px",
          marginBottom: "30px",
        }}
      >
        <a
          className="linkInstituto"
          target="blank"
          href="https://www.skypixel.com/photo360s/look-at-this-amazing-shot-i-took-with-my-spark-3afc7506-489d-4eed-8cce-93b3dc6a7c43"
        >
          <img className="imgInstituto" src={instituto360} alt="Instituto360" />
        </a>
        <a
          className="linkClima"
          target="blank"
          href="https://climaaovivo.com.br/mg/itamonte-instituto-alto-montana"
        >
          <img className="imgClima" src={Clima} alt="Clima" />
        </a>
      </Box>
    </Box>
  );
}

export default MapIframeBox;
