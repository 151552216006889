import './styles.css'
import FazendaImg from '../../assets/Logo_Fazenda_Pinhao_Assado.png';
import RPPNImg from '../../assets/Logo_RPPN.png';
import AbrigoImg from '../../assets/Logo_Abrigo.png';

function Logos() {
  return (
    <div className='container-logos'>
        <img src={FazendaImg} alt='fazenda' style={{width: "25%", objectFit: 'contain'}}/>
        <img src={RPPNImg} alt='RPPN' style={{width: "25%", objectFit: 'contain'}}/>
        <img src={AbrigoImg} alt='Abrigo' style={{width: "25%", objectFit: 'contain'}}/>
    </div>
  )
}

export default Logos