import React, { useState } from "react";
import {
  Container,
  Grid,
  TextField,
  FormControl,
  Button,
  Typography,
  Box,
} from "@mui/material";
import instituto360 from "../../assets/Instituto360.png";
import Clima from "../../assets/TempoClima.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useWeb3forms from "use-web3forms";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

const schema = yup
  .object({
    nome: yup.string().required("Você precisa escrever seu nome"),
    telefone: yup.string().required("Você precisa escrever seu telefone"),
    email: yup.string().required("Você precisa escrever seu e-mail"),
    mensagem: yup.string().required("Você precisa escrever uma mensagem"),
  })
  .required();

const Contato = () => {
  const { submit } = useWeb3forms({
    apikey: "cd9b168a-638e-4875-b397-ebc398176836",
    onSuccess: () => {
      toast.success("Contato enviado com sucesso");
      reset();
    },
    onError: () => {
      toast.error("Ops, algo deu errado. Tente novamente!");
      reset();
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Container>
      <Grid container sx={{ gridGap: "10%" }} mt={8} mb={5}>
        <Grid item xs={12} md={6} mb={3}>
          <form>
            <FormControl fullWidth>
              <Box
                mt={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 700,
                    fontSize: "36px",
                    textAlign: "center",
                  }}
                >
                  Como podemos ajudar?
                </Typography>
                {/* <Box>
                <img src={mail} alt="Mail" />
              </Box> */}
              </Box>
              <TextField
                error={!!errors.nome}
                fullWidth
                label="Nome:"
                variant="standard"
                margin="dense"
                sx={{ marginTop: "15px" }}
                {...register("nome")}
                helperText={errors.nome && errors.nome.message}
              />
              <InputMask mask="(99)9 9999-9999" {...register("telefone")}>
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    error={!!errors.telefone}
                    fullWidth
                    label="Telefone:"
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: "15px" }}
                    helperText={errors.telefone && errors.telefone.message}
                  />
                )}
              </InputMask>
              <TextField
                error={!!errors.email}
                type="email"
                fullWidth
                label="Email:"
                variant="standard"
                margin="dense"
                sx={{ marginTop: "15px" }}
                {...register("email")}
                helperText={errors.email && errors.email.message}
              />
              <TextField
                error={!!errors.mensagem}
                id="outlined-multiline-static"
                label="Conte-nos um pouco mais:"
                multiline
                rows={4}
                margin="dense"
                sx={{ marginTop: "25px" }}
                {...register("mensagem")}
                helperText={errors.mensagem && errors.mensagem.message}
              />
              <Button
                variant="contained"
                type="submit"
                onClick={handleSubmit(submit)}
                sx={{ width: 100, alignSelf: "center", marginTop: 3 }}
              >
                Enviar
              </Button>
            </FormControl>
          </form>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid black",
              borderRadius: "20px",
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11377.583245548118!2d-44.7958801!3d-22.3526094!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7c2ad97e0c3fff67!2sInstituto%20Alto%20Montana%20da%20Serra%20Fina!5e1!3m2!1spt-BR!2sbr!4v1667165357248!5m2!1spt-BR!2sbr"
              style={{
                border: 0,
                borderRadius: "16px",
                margin: "40px",
                height: "30vh",
              }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
            <Box
              sx={{
                display: "flex",
                gap: "25px",
                margin: "30px",
                marginBottom: "80px",
              }}
            >
              <a
                className="linkInstituto"
                target="blank"
                href="https://www.skypixel.com/photo360s/look-at-this-amazing-shot-i-took-with-my-spark-3afc7506-489d-4eed-8cce-93b3dc6a7c43"
              >
                <img
                  className="imgInstituto"
                  src={instituto360}
                  alt="Instituto360"
                />
              </a>
              <a
                className="linkClima"
                target="blank"
                href="https://climaaovivo.com.br/mg/itamonte-instituto-alto-montana"
              >
                <img className="imgClima" src={Clima} alt="Clima" />
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contato;
