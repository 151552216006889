import { WhatsApp } from "@mui/icons-material";
import React from "react";

function WhatsappButton() {
  return (
    <div>
      <a
        href="https://wa.me/5535992604484"
        style={{
          position: "fixed",
          width: "60px",
          height: "60px",
          bottom: "40px",
          right: "40px",
          backgroundColor: "#25d366",
          color: "#fff",
          borderRadius: "50px",
          textAlign: "center",
          fontSize: "30px",
          boxShadow: "1px 1px 2px #888",
          zIndex: "1000",
        }}
        target="blank"
      >
        <WhatsApp fontSize="large" style={{ height: "100%" }} />
      </a>
    </div>
  );
}

export default WhatsappButton;
