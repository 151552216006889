import {
  Container,
  IconButton,
  Toolbar,
  SwipeableDrawer,
  Divider,
  Button,
} from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ButtonAoVivo, MenuItem, SwipeMenu } from "./styles";
import { NavLinks } from "./Navbar";
import aoVivo from "../../assets/ao_vivo.png";

const Topbar = () => {
  const [open, setOpen] = useState(false);
  return (
    <Toolbar sx={{ backgroundColor: "#466FC6" }}>
      <Container
        sx={{
          width: "80%",
          justifyContent: "center",
          alignItems: "center",
          display: { md: "flex", xs: "none" },
        }}
      >
        <MenuItem menu="topbar" to="/quem-somos">
          QUEM SOMOS
        </MenuItem>
        <MenuItem menu="topbar" to="/fazenda-pinhao-assado">
          FAZENDA PINHÃO ASSADO E RPPN ALTO MONTANA
        </MenuItem>
        <MenuItem menu="topbar" to="/contato">
          CONTATO
        </MenuItem>
        <ButtonAoVivo
          menu="topbar"
          href="https://climaaovivo.com.br/mg/itamonte-instituto-alto-montana"
          target="blank"
        >
          AO VIVO
          <img
            src={aoVivo}
            alt="ao vivo"
            style={{ marginLeft: "10px", width: "25%" }}
          />
        </ButtonAoVivo>
      </Container>
      <IconButton
        sx={{ color: "#FFF", display: { md: "none", xs: "block" } }}
        onClick={() => setOpen(true)}
      >
        <MenuIcon />
      </IconButton>
      <SwipeMenu
        anchor="left"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <div
          onClick={() => setOpen(false)}
          onKeyPress={() => setOpen(false)}
          role="button"
          tabIndex={0}
        >
          <IconButton>
            <ChevronLeftIcon sx={{ color: "#FFF" }} />
          </IconButton>
        </div>
        <Divider />
        <MenuItem menu="topbar" to="/quem-somos" onClick={() => setOpen(false)}>
          QUEM SOMOS
        </MenuItem>
        <MenuItem
          menu="topbar"
          to="/fazenda-pinhao-assado"
          onClick={() => setOpen(false)}
        >
          FAZENDA PINHÃO ASSADO E RPPN ALTO MONTANA
        </MenuItem>
        <MenuItem menu="topbar" to="/contato" onClick={() => setOpen(false)}>
          CONTATO
        </MenuItem>
        <ButtonAoVivo
          sx={{
            textAlign: "unset",
            justifyContent: "flex-start",
            padding: "0",
            paddingLeft: "18px",
          }}
          menu="topbar"
          href="https://climaaovivo.com.br/mg/itamonte-instituto-alto-montana"
          target="blank"
        >
          AO VIVO
          <img
            src={aoVivo}
            alt="ao vivo"
            style={{ marginLeft: "10px", width: "25px" }}
          />
        </ButtonAoVivo>
        <Divider />
        <NavLinks setOpen={setOpen} />
      </SwipeMenu>
    </Toolbar>
  );
};

export default Topbar;
