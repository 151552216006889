import Actions from '../../components/Actions';
import Banner from '../../components/Banner';
import Gallery from '../../components/Gallery';
import Logos from '../../components/Logos';

function Home() {
    return (
        <>
            <Logos />
            <Gallery />
            <Actions />
        </>
    );
}

export default Home;