import { Container } from "@mui/material";
import "./styles.css";
import arvoreImg from "../../assets/arvores.png";
import animaisImg from "../../assets/animaisPes.png";
import areaPreservadaImg from "../../assets/areaPreservada.png";
import livroImg from "../../assets/livro.png";

function Actions() {
  return (
    <section className="actions">
      <h2 style={{ color: "black" }}>
        Conheça nossas ações,
        <br />
        engaje-se em nossos projetos, venha nos visitar!
      </h2>
      <Container
        sx={{ display: "flex", textAlign: "center", alignItems: "flex-start" }}
      >
        <div>
          <img src={arvoreImg} alt="desenho de árvore" />
          <span>
            10.000
            <br />
            Árvores identificadas
          </span>
        </div>
        <div>
          <img src={animaisImg} alt="desenho de pegadas" />
          <span>
            640
            <br />
            Animais registrados
          </span>
        </div>
        <div>
          <img src={areaPreservadaImg} alt="desenho de montanha" />
          <span>
            1.000.000m²
            <br />
            Área preservada
          </span>
        </div>
        <div>
          <img src={livroImg} alt="desenho de livro" />
          <span>
            120
            <br />
            Trabalhos científicos publicados
          </span>
        </div>
      </Container>
    </section>
  );
}

export default Actions;
