import React from "react";
import { Container } from "@mui/material";
import "../../globalStyles/styles.css";
import "./styles.css";
import imgVoluntariado from "../../assets/voluntariado.png";

const Educacao = () => {
  return (
    <Container className="educacaoPage">
      <h1 className="titulo">Educação</h1>
      <hr />

      <p className="descricao">
        Os Programas educacionais do Instituto Alto Montana são construídos de
        forma participativa, fornecendo uma alternativa diferenciada e
        complementar ao processo pedagógico, de modo lúdico e multidisciplinar,
        visando proporcionar aos estudantes de escolas públicas e privadas, uma
        experiência única de aprendizado, que seja transformadora e utilize de
        atividades vivenciais diretamente em ambiente natural.
      </p>
      <p className="descricao">
        As aulas ao ar livre potencializam o aprendizado do educando e permite
        diversas experiências, para além daquelas vivenciadas no ambiente
        escolar.
      </p>

      <p className="descricao">Nossos objetivos são:</p>

      <ul>
        <li>
          Promover atividades alternativas, diferenciais e complementares ao
          processo pedagógico;
        </li>
        <li>
          Gerar o intercâmbio entre teoria e prática por meio da associação e
          discussão de conteúdos de forma vivencial;
        </li>
        <li>
          Sensibilizar para a criticidade, criatividade, tomada de decisão
          responsável e o desenvolvimento de habilidades socioemocionais;
        </li>
        <li>
          Evidenciar a importância da conservação e preservação dos ambientes.
        </li>
      </ul>

      <h2 className="subtitulo">Cursos</h2>
      <p className="descricao">
        O Instituto Alto Montana possui parcerias com diversas universidades e
        profissionais autônomos que desenvolvem cursos com diferentes temáticas
        ambientais em nossa área.
      </p>

      <h2 className="subtitulo">Visitas Técnicas</h2>
      <p className="descricao">
        Graças aos mais de 1000 hectares de Floresta Atlântica de Altitude e o
        seu fácil acesso, a área do Instituto Alto Montana da Serra Fina é ideal
        para realização de visitas técnicas de escolas de ensino fundamental,
        médio, superior e pós-graduação por meio da troca de conhecimentos. O
        objetivo é promover interação, organização e cooperação entre os mesmos,
        a fim de gerar o debate em campo dentro de uma visão ecossistêmica
        abordando temas ligados à Agroecologia e ao Uso Público de Áreas
        Naturais Protegidas.
      </p>

      <h2 className="subtitulo">Voluntariado e Estágios</h2>
      <p className="descricao">
        As atividades de voluntariado e os estágios de vivência tem como foco
        trazer aos participantes uma experiência transformadora de observação,
        práticas e relação com a natureza, por meio da troca de conhecimentos. O
        objetivo é promover interação, organização e cooperação entre os mesmos,
        a fim de gerar o debate em campo dentro de uma visão ecossistêmica
        abordando temas ligados à Agroecologia e ao Uso Público de Áreas
        Naturais Protegidas.
      </p>

      <img
        src={imgVoluntariado}
        alt="voluntariado na horta"
        className="imgVoluntariado"
      />
    </Container>
  );
};

export default Educacao;
