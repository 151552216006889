import { Container, Toolbar } from "@mui/material";
import { MenuItem } from "./styles";

export const NavLinks = ({ setOpen }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        justifyContent: "space-between",
        backgroundColor: "white",
      }}
    >
      <MenuItem to="/" onClick={() => setOpen(false)}>
        Home
      </MenuItem>
      <MenuItem to="/pesquisa-cientifica" onClick={() => setOpen(false)}>
        Pesquisa Científica
      </MenuItem>
      <MenuItem to="/educacao" onClick={() => setOpen(false)}>
        Educação
      </MenuItem>
      <MenuItem to="/producao-agroecologica" onClick={() => setOpen(false)}>
        Produção Agroecológica
      </MenuItem>
      <MenuItem to="/esportes-na-natureza" onClick={() => setOpen(false)}>
        Esportes na Natureza
      </MenuItem>
      <MenuItem to="/turismo-de-montanha" onClick={() => setOpen(false)}>
        Turismo de Montanha
      </MenuItem>
      <MenuItem to="/abrigo-alto-montana" onClick={() => setOpen(false)}>
        Abrigo Alto Montana
      </MenuItem>
    </Container>
  );
};

const NavBar = () => {
  return (
    <Toolbar
      sx={{ backgroundColor: "white", display: { md: "flex", xs: "none" } }}
    >
      <Container
        maxWidth="md"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <NavLinks />
      </Container>
    </Toolbar>
  );
};

export default NavBar;
