import { Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "../components/ScrolltoTop";
import Abrigo from "../pages/Abrigo";
import Contato from "../pages/Contato";
import Educacao from "../pages/Educacao";
import Esportes from "../pages/Esportes";
import Fazenda from "../pages/Fazenda";
import Home from "../pages/Home";
import Pesquisa from "../pages/Pesquisa";
import Producao from "../pages/Producao";
import QuemSomos from "../pages/QuemSomos";
import Turismo from "../pages/Turismo";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contato" element={<Contato />} />
      <Route path="/turismo-de-montanha" element={<Turismo />} />
      <Route path="/abrigo-alto-montana" element={<Abrigo />} />
      <Route path="/pesquisa-cientifica" element={<Pesquisa />} />
      <Route path="/producao-agroecologica" element={<Producao />} />
      <Route path="/educacao" element={<Educacao />} />
      <Route path="/esportes-na-natureza" element={<Esportes />} />
      <Route path="/fazenda-pinhao-assado" element={<Fazenda />} />
      <Route path="/quem-somos" element={<QuemSomos />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
