import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../globalStyles/styles.css";
import "./styles.css";
import mtbImg from "../../assets/mtbimg.png";
import corridaImg from "../../assets/corrida-montanha.png";
import voolivreImg from "../../assets/voolivre-img.png";
import slackImg from "../../assets/slack-img.png";
import cascadingImg from "../../assets/cascading-img.png";

const Esportes = () => {
  return (
    <Container>
      <h1 className="titulo">ESPORTES NA NATEREZA</h1>
      <hr />
      <Accordion sx={{ marginBottom: "18px", marginTop: "18px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse" }}
        >
          <Typography variant="h6">Corrida de Montanha</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Trilhas e circuitos para treinos e competições de corrida de
            montanha com diferentes níveis técnicos, em percursos de 3,5 e 30 km
            (com possibilidade de ampliação para áreas vizinhas em até 60km) em
            área variando entre 1400 e 2540 metros de altitude. Temos parcerias
            com guias e treinadores especializados em corridas de montanha.
          </Typography>
          <a
            target="blank"
            className="linkMapa"
            href="https://pt.wikiloc.com/wikiloc/user.do?id=6998179"
          >
            Link para mapas de trilhas
          </a>
          <a
            target="blank"
            className="linkMapa"
            href="https://api.whatsapp.com/send/?phone=5535992604484"
          >
            Para saber mais, clique aqui!
          </a>
        </AccordionDetails>
      </Accordion>
      <img
        style={{ borderRadius: "15px" }}
        src={corridaImg}
        alt="Descendo a trilha de bicicleta"
        className="mtbImg"
      />
      <Accordion sx={{ marginBottom: "18px", marginTop: "18px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse" }}
        >
          <Typography variant="h6">Mountain Bike - MTB</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Trilhas para mountain bike em estradas e singletracks (XCM e XCO)
            com possibilidades de trilhas entre 3 e 16 km, técnicas, uphill e
            downhill, bem como para passeios de contemplação da natureza
          </Typography>
          <a
            className="linkMapa"
            href="https://pt.wikiloc.com/wikiloc/user.do?id=6998179"
          >
            Link para mapas de trilhas
          </a>
        </AccordionDetails>
      </Accordion>
      <img
        style={{ borderRadius: "15px" }}
        src={mtbImg}
        alt="Descendo a trilha de bicicleta"
        className="mtbImg"
      />
      <a
        target="blank"
        className="linkMapa"
        href="https://api.whatsapp.com/send/?phone=5535992604484"
      >
        Para saber mais, clique aqui!
      </a>
      <Accordion sx={{ marginBottom: "18px", marginTop: "18px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse" }}
        >
          <Typography variant="h6">Vôo Livre</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A Rampa Alto Montana de voo livre encontra-se na área da Reserva
            Particular do Patrimônio Natural - RPPN Alto Montana. A sede do
            Instituto, a 1400 metros de altitude, na Fazenda Pinhão Assado, tem
            seu acesso pela rodovia BR 354, Km 768, bairro rural Engenho da
            Serra, cerca de 230 km do Rio de Janeiro e 360 km de São Paulo. Na
            área encontram-se as estruturas do Abrigo Alto Montana (acomodações
            para hospedagem, recepção, auditório, bar, sala de TV, restaurante,
            cozinhas e WiFi livre). É possível aos pilotos se hospedarem no
            Abrigo Alto Montana, perante consulta e agendamento prévio. As
            condições de voo para os pilotos que decolam da Rampa Alto Montana
            são magníficas, tanto no sentido da paisagem e belezas naturais
            típicas das Terras Altas da Mantiqueira, quanto pela possibilidade
            de tiradas em se tratando de voo de XC. A decolagem ocorre há cerca
            de 2.100 m de altitude, havendo um desnível de 900 m até o pouso
            oficial e com diversas possibilidades de pouso alternativo ao longo
            desse trajeto. Importante salientar que a rampa se situa no caminho
            da Travessia da Serra Fina, considerada uma das mais belas, difíceis
            e técnicas travessia de montanha do Brasil. Da sede até a rampa
            trafega-se por uma estrada de terra com cerca de 8 Km em boas
            condições de uso.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <img src={voolivreImg} alt="Vôo livre de parapente" className="vooImg" />

      <p className="descricao">Quadrante: NE|N|NW</p>
      <p className="descricao">WayPoint: 22° 21' 44.568"S 44° 50' 37.946"W </p>
      <p className="descricao">Frequência: 144.550 </p>
      <p className="descricao">
        Decolagem: Duas rampas de madeira para asa e grande área para decolagem
        de parapente{" "}
      </p>
      <p className="descricao">
        Pouso: Ao longo da estrada de acesso a Itamonte{" "}
      </p>
      <p className="descricao">Melhor Época: De setembro à dezembro</p>
      <a
        className="linkMapa"
        target="blank"
        href="https://firebasestorage.googleapis.com/v0/b/instituto-altomontana.appspot.com/o/Regras%20da%20Rampa%20Alto%20Montana.pdf?alt=media&token=82c5ddb4-e729-40b2-acd6-9c145d9e3425"
      >
        Regras da Rampa
      </a>
      <a
        target="blank"
        className="linkMapa"
        href="https://api.whatsapp.com/send/?phone=5535992604484"
      >
        Para saber mais, clique aqui!
      </a>

      <Accordion sx={{ marginBottom: "18px", marginTop: "18px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse" }}
        >
          <Typography variant="h6">Slackline</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nas atividades de slackline o objetivo é proporcionar vivências em
            contato com a natureza, por meio do turismo de aventura com a
            prática esportiva ao ar livre. O Slackline é um esporte que trabalha
            o equilíbrio corporal e mental, durante a caminhada sobre uma fita
            elástica esticada entre dois pontos fixos. Suas principais
            modalidades são: trickline, waterline, longline e highline, este, é
            a modalidade mais desafiadora do Slackline, onde a atividade
            consiste, basicamente, em equilibrar-se em uma fita ancorada em dois
            pontos, a partir de 20 metros de altura, com equipamentos de
            segurança. Pode ser feito entre formações rochosas de cachoeiras,
            árvores, canyons, prédios, etc.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <img src={slackImg} alt="Slackline" className="slackImg" />
      <a
        target="blank"
        className="linkMapa"
        href="https://api.whatsapp.com/send/?phone=5535992604484"
      >
        Para saber mais, clique aqui!
      </a>

      <Accordion sx={{ marginBottom: "18px", marginTop: "18px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse" }}
        >
          <Typography variant="h6">Cascading</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            O Cascading é uma atividade de aventura que consiste na descida de
            cachoeiras e cascatas, utilizando técnicas verticais de rapel e
            equipamentos de escalada e montanhismo.
          </Typography>
          <Typography>
            No Instituto Alto Montana as práticas do cascading são realizadas na
            Cachoeira Pinhão Assado, com 45 metros de altura.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <img
        src={cascadingImg}
        alt="Rapel na cachoeira"
        className="cascadingImg"
      />
      <a
        target="blank"
        className="linkMapa"
        href="https://api.whatsapp.com/send/?phone=5535992604484"
      >
        Para saber mais, clique aqui!
      </a>
    </Container>
  );
};

export default Esportes;
