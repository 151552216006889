import { BrowserRouter } from "react-router-dom";
import Banner from "./components/Banner";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrolltoTop";
import WhatsappButton from "./components/WhatsappButton";
import AppRoutes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Header />
        <Banner />
        <AppRoutes />
        <Footer />
        <WhatsappButton />
      </ScrollToTop>
      <ToastContainer theme="colored" autoClose={2000} />
    </BrowserRouter>
  );
}

export default App;
