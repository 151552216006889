import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styles.css";
import home from "../../assets/banner.webp";
import turismo from "../../assets/turismo-banner.png";
import contato from "../../assets/contato-banner.png";
import abrigo from "../../assets/abrigo-banner.png";
import pesquisa from "../../assets/pesquisa-banner.png";
import producao from "../../assets/producao-banner.png";
import educacao from "../../assets/educacao-banner.png";
import esportes from "../../assets/esportes-banner.png";
import fazenda from "../../assets/fazenda-banner.png";
import quemsomos from "../../assets/quemsomos-banner.png";

function Banner() {
  const location = useLocation();
  const [img, setImg] = useState();
  const [position, setPosition] = useState("");

  const setBanner = () => {
    switch (location.pathname) {
      case "/":
        setImg(home);
        setPosition("center");
        break;
      case "/turismo-de-montanha":
        setImg(turismo);
        setPosition("center");
        break;
      case "/contato":
        setImg(contato);
        setPosition("center");
        break;
      case "/abrigo-alto-montana":
        setImg(abrigo);
        setPosition("center");
        break;
      case "/pesquisa-cientifica":
        setImg(pesquisa);
        setPosition("center");
        break;
      case "/producao-agroecologica":
        setImg(producao);
        setPosition("center");
        break;
      case "/educacao":
        setImg(educacao);
        setPosition("left");
        break;
      case "/esportes-na-natureza":
        setImg(esportes);
        setPosition("center");
        break;
      case "/fazenda-pinhao-assado":
        setImg(fazenda);
        setPosition("center");
        break;
      case "/quem-somos":
        setImg(quemsomos);
        setPosition("center");
        break;
    }
  };

  useEffect(() => {
    setBanner();
  }, [location]);

  return (
    <div
      style={{
        backgroundImage: `url(${img})`,
        backgroundPosition: `${position}`,
      }}
      className="container"
    >
      {location.pathname == "/" && (
        <div className="titleContainer">
          <span>SEJAM BEM-VINDOS AO INSTITUTO ALTO MONTANA DA SERRA FINA</span>
          <span>PESQUISA, EDUCAÇÃO E CONSERVAÇÃO DA BIODIVERSIDADE</span>
        </div>
      )}
    </div>
  );
}

export default Banner;
