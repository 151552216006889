import React from "react";
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "../../globalStyles/styles.css";
import "./styles.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import imgAves from "../../assets/obs-aves.png";
import imgTrilhas from "../../assets/trilhas.png";
import imgCachoeira from "../../assets/cachoeira.png";
import imgVisitacao from "../../assets/visitacao.png";

const Turismo = () => {
  return (
    <Container>
      <h1 className="titulo">TURISMO DE MONTANHA</h1>
      <hr />
      <Accordion sx={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse" }}
        >
          <Typography variant="h6">Observação de Aves</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            As aves são um grande atrativo no Instituto Alto Montana! Desde o
            início dos estudos, em junho de 2009, foram registradas na área da
            Fazenda Pinhão Assado e RPPN Alto Montana 280 espécies de aves,
            pertencentes a 56 famílias e 22 ordens. Dentre estas espécies, 76
            constituem endemismos da Mata Atlântica, o que representa
            aproximadamente 35% do total de espécies consideradas endêmicas
            deste bioma. Encontram-se na área 15 espécies ameaçadas de extinção,
            em nível global, nacional ou estadual, e outras 11 espécies
            categorizadas como quase ameaçadas. Além de abrigar uma avifauna
            rica e diversificada, a RPPN também serve de refúgio para outras
            inúmeras espécies da fauna nativa, algumas ameaçadas de extinção,
            como a onça-parda (Puma concolor), a jaguatirica (Leopardus pardalis
            mitis) e o loboguará (Chrysocyon brachyurus). Dentre os habitantes
            mais carismáticos, encontra-se o ágil caxinguelê ou esquilo
            brasileiro (Guerlinguetus ingrami), grande apreciador dos pinhões da
            Araucaria angustifolia. Nas matas é possível observar pequenos
            bandos de macacos-prego (Sapajus nigritus) e de sauás (Callicebus
            nigrifrons), cuja algazarra ouvese ecoando pelas grotas da serra
            logo após o amanhecer. Ao caminhar pelas estradas e trilhas da RPPN,
            diversas formas de vida, algumas mais discretas, se revelam aos
            olhares mais atentos, como o bicho-pau-falso (Proscopiidae) e o
            diminuto e incomum sapinho de-chifres (Proceratophrys sp), ambos
            quase indetectáveis no meio da serapilheira. Além da avifauna,
            também ocorrem nas matas e campos de altitude diversas espécies de
            lagartos e de serpentes, a maioria delas inofensivas, embora também
            ocorram espécies peçonhentas como a urutu (Bothrops fonsecai) e a
            cascavel (Crotalus durissus).
          </Typography>
          <a
            target="blank"
            className="linkMapa"
            href="https://firebasestorage.googleapis.com/v0/b/instituto-altomontana.appspot.com/o/07%20-%20Guia%20de%20Aves%20da%20RPPN%20Alto%20Montana.pdf?alt=media&token=037460aa-b4c1-4690-adca-47a5ffad1fc6"
          >
            Guia de Aves da RPPN
          </a>
          <a
            target="blank"
            className="linkMapa"
            href="https://api.whatsapp.com/send/?phone=5535992604484"
          >
            Para saber mais, clique aqui!
          </a>
        </AccordionDetails>
      </Accordion>

      <img className="imgTurismoPage" src={imgAves} />

      <Accordion sx={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse" }}
        >
          <Typography variant="h6">Trilhas e Circuitos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Trilhas para atrativos naturais e Circuitos com diferentes níveis
            técnicos e de dificuldade, sinalizadas, autoguiadas ou guiadas.
            Acesso a rede de trilhas da Serra Fina, Alto dos Ivos e Pico dos
            Três Estados. Temos parcerias com guias locais especializados em
            caminhadas, corridas de montanha e escaladas.
          </Typography>
          <a
            target="blank"
            className="linkMapa"
            href="https://pt.wikiloc.com/wikiloc/user.do?id=6998179"
          >
            Acesse o mapa de trilhas do Alto Montana aqui
          </a>
          <a
            target="blank"
            className="linkMapa"
            href="https://api.whatsapp.com/send/?phone=5535992604484"
          >
            Para saber mais, clique aqui!
          </a>
        </AccordionDetails>
      </Accordion>
      <img className="imgTurismoPage" src={imgTrilhas} />

      <Accordion sx={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse" }}
        >
          <Typography variant="h6">Cachoeiras</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No Instituto você pode acessar em distâncias entre 400 e 700 metros
            áreas com cachoeiras para contemplação e banhos, ou se preferir em
            um circuito de 3 km tem-se o acesso pelo Circuito Pinhão Assado a 05
            cachoeiras
          </Typography>
          <a
            target="blank"
            className="linkMapa"
            href="https://pt.wikiloc.com/wikiloc/user.do?id=6998179"
          >
            Link para mapas de trilhas
          </a>
          <a
            target="blank"
            className="linkMapa"
            href="https://api.whatsapp.com/send/?phone=5535992604484"
          >
            Para saber mais, clique aqui!
          </a>
        </AccordionDetails>
      </Accordion>

      <img className="imgTurismoPage" src={imgCachoeira} />

      <Accordion sx={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse" }}
        >
          <Typography variant="h6">Visitação</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Estamos abertos a visitação de quinta a domingo e feriados entre 8 e 18 horas, porém quando feriados ocorrem no início da semana a programação de fechamento do instituto pode mudar. Sugerimos que nos consulte previamente à sua visita.
          </Typography>
          <a
            target="blank"
            className="linkMapa"
            href="https://pt.wikiloc.com/wikiloc/user.do?id=6998179"
          >
            Link para mapas de trilhas
          </a>
          <a
            target="blank"
            className="linkMapa"
            href="https://api.whatsapp.com/send/?phone=5535992604484"
          >
            Para saber mais, clique aqui!
          </a>
        </AccordionDetails>
      </Accordion>
      <img className="imgTurismoPage" src={imgVisitacao} />
    </Container>
  );
};

export default Turismo;
