import React from "react";
import { Container, Grid } from "@mui/material";
import "../../globalStyles/styles.css";
import "./styles.css";
import abrigo1 from "../../assets/abrigo1.png";
import abrigo2 from "../../assets/abrigo2.png";
import abrigo3 from "../../assets/abrigo3.png";
import logoAbrigo from "../../assets/Logo_Abrigo.png";

const Abrigo = () => {
  return (
    <Container>
      <h1 className="titulo">ABRIGO ALTO MONTANA</h1>
      <hr />
      <p className="descricao">
        O Abrigo Alto Montana disponibiliza acomodações em apartamentos de 2
        quartos (1 quarto com cama de casal e outro com duas camas de solteiro),
        banheiro e varanda ou jardim, onde temos como base de valores, o número
        de pessoas que ocupam o apto e o uso ou não de nossas roupas de cama e
        banho.
      </p>
      <p className="descricao">
        Vindo para o Instituto Alto Montana, os visitantes têm acesso a toda
        área natural da propriedade que tem mais de 30 km de trilhas e estradas
        para caminhadas e MTB, cachoeiras e piscinas naturais a uma distância
        entre 400 e 700 metros dos apartamentos. É ainda possível praticar
        outras atividades que são pré-agendadas e possuem taxas extras, como:
        prática de observação de aves (284 espécies já registradas na área),
        visitação a áreas de pesquisas científicas, cascading (rapel na
        cachoeira Pinhão Assado), acesso a Rampa de Voo Livre de veículo, voo
        duplo de parapente, e muito mais...
      </p>

      <Grid
        container
        spacing={{ xs: 4, md: 6, lg: 8 }}
        className="abrigoImgContainer"
      >
        <Grid item xs={12} md={4}>
          <img className="imgAbrigo" src={abrigo1} alt="abrigo" />
        </Grid>
        <Grid item xs={12} md={4}>
          <img className="imgAbrigo" src={abrigo2} alt="abrigo" />
        </Grid>
        <Grid item xs={12} md={4}>
          <img className="imgAbrigo" src={abrigo3} alt="abrigo" />
        </Grid>
      </Grid>

      <p className="descricao">
        Temos serviços de alimentação para grupos a partir de 15 pessoas. Não
        tendo este número, disponibilizamos cozinha para uso compartilhado dos
        visitantes e assim estes preparam suas refeições como preferirem. Aos
        finais de semana o Bar I’AM funciona das 11 às 17 horas.
      </p>

      <a
        className="linkMapa link centerText"
        target="_blank"
        href="https://drive.google.com/file/d/1PmjHspYOQvs9QH2W4Lbu1kKStMy23L1A/view?usp=sharing"
      >
        Consulte Cardápio e Valores do Bar I'AM
      </a>

      <a
        className="linkMapa link centerText"
        target="_blank"
        href="https://drive.google.com/file/d/1wmEbCtOe4pi6UQwzZo2fDuSBCnGCp_7_/view?usp=sharing"
      >
        Consulte o Cardápio de nosso Programa de Réveillon
      </a>

      <p className="descricao">
        Aqui no instituto somos amigos dos animais, porém informamos que temos 4
        pets no instituto que são muito simpáticos e adoram fazer amizade. Desta
        forma é essencial que seu pet seja sociável. Assim sendo, será um prazer
        recebê-lo.
      </p>

      <p className="descricao">
        - Não é permitido a presença de pets nas áreas internas comuns e de
        alimentação. <br />
        - Trazendo seu pet, a acomodação acontecerá exclusivamente nos aptos
        térreos, com saídas direto para o jardim.
        <br />
        - Ao passear pelas trilhas com seu pet, use sempre a guia.
        <br />
        - Danos ao instituto ou a terceiros, causados pelo pet são de
        responsabilidade de seu tutor.
        <br />
      </p>

      <a
        className="linkMapa centerText link"
        target="blank"
        href="https://api.whatsapp.com/send/?phone=5535992604484"
      >
        Reserve agora a experiência de viver dias sem pressa, em meio a natureza
        conservada!
      </a>

      <img src={logoAbrigo} className="logoAbrigo" alt="logo do abrigao" />
    </Container>
  );
};

export default Abrigo;
