import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import "./styles.css";

function Gallery() {
  return (
    <Container sx={{ maxWidth: 1050 }}>
      <h2 className="galleryTitle">ACONTECE NO INSTITUTO</h2>
      <div className="row">
        <Link
          style={{ textDecoration: "unset" }}
          to="/pesquisa-cientifica"
          className="card hospedagem"
        >
          <span>Pesquisa Científica</span>
        </Link>
        <Link
          style={{ textDecoration: "unset" }}
          to="/educacao"
          className="card passaros"
        >
          <span>Educação</span>
        </Link>
        <Link
          style={{ textDecoration: "unset" }}
          to="/pesquisa-cientifica"
          className="card corrida"
        >
          <span>
            Conservação
            <br />
            da Biodiversidade
          </span>
        </Link>
      </div>
      <div className="row">
        <Link
          style={{ textDecoration: "unset" }}
          to="/turismo-de-montanha"
          className="card piscinas"
        >
          <span>Turismo de Montanha</span>
        </Link>
        <Link
          style={{ textDecoration: "unset" }}
          to="/esportes-na-natureza"
          className="card sonhos"
        >
          <span>
            Esportes
            <br />
            na
            <br />
            Natureza
          </span>
        </Link>
        <Link
          style={{ textDecoration: "unset" }}
          to="/producao-agroecologica"
          className="card natureza"
        >
          <span>Agroecologia</span>
        </Link>
      </div>
      <div className="row">
        <Link
          style={{ textDecoration: "unset" }}
          to="/turismo-de-montanha"
          className="card fauna"
        >
          <span>Visitação Day Use</span>
        </Link>
        <Link
          style={{ textDecoration: "unset" }}
          to="/abrigo-alto-montana"
          className="card ciclismo"
        >
          <span>Hospedagem</span>
        </Link>
        <Link
          style={{ textDecoration: "unset" }}
          to="/quem-somos"
          className="card vooDuplo"
        >
          <span>Eventos</span>
        </Link>
      </div>
    </Container>
  );
}

export default Gallery;
