import { AppBar } from "@mui/material";
import NavBar from "./Navbar";
import Topbar from "./Topbar";

const Header = () => {
  return (
    <AppBar position="static">
      <Topbar />
      <NavBar />
    </AppBar>
  );
};

export default Header;
