import React from "react";
import { Container, Box, Button } from "@mui/material";
import "../../globalStyles/styles.css";
import "./styles.css";
import fazendaImg from "../../assets/fazendabranco-img.png";

import FazendaImg from "../../assets/Logo_Fazenda_Pinhao_Assado.png";
import RPPNImg from "../../assets/Logo_RPPN.png";
import MapIframeBox from "../../components/MapIframeBox";

const Fazenda = () => {
  return (
    <Container>
      <h1 className="titulo">FAZENDA PINHÃO ASSADO E RPPN ALTO MONTANA</h1>
      <hr />

      <h2 className="subtitulo">Histórico</h2>
      <p className="descricao">
        Considerada uma das principais cadeias montanhosas do sudeste
        brasileiro, a Serra da Mantiqueira se destaca também por sua riqueza
        biológica, que lhe conferiu o status de área prioritária para a
        conservação da biodiversidade em Minas Gerais, conforme estudo realizado
        em 1998 pela Secretaria do Estado de Meio Ambiente de Minas Gerais e
        instituições parceiras (Costa et al, 1998). O Município de Itamonte, com
        área territorial de 431,7 Km2, está situado na parte alta da Serra da
        Mantiqueira, com altitudes que variam de 880 a 2.791 metros. Apresenta
        um dos maiores índices de cobertura florestal remanescentes do Bioma
        Mata Atlântica no Estado de Minas Gerais, com 60% de seu território
        (IEF, 2007). As unidades de conservação presentes na região ocupam 80%
        de seu território.
      </p>
      <img src={fazendaImg} alt="Fazendo Branco" className="fazendaBrancoImg" />
      <p className="descricao">
        Inserida neste contexto, a Fazenda Pinhão Assado é composta por uma área
        de mais de 1000 hectares localizados no município de Itamonte - MG. A
        Fazenda foi adquirida pelos atuais proprietários Roberto Campos Rocha e
        Sandra Mara Flores, em 2007. Com esta nova administração, a atividade
        hoteleira anteriormente existente foi cessada e realizado um
        planejamento global da propriedade. Neste processo, foram definidos
        <b> 5 eixos vocacionais de uso para a Fazenda</b>, sendo estes:
        Conservação da Biodiversidade, Pesquisa e Educação, Turismo de montanha,
        Esportes na natureza, Produção Agroecológica.
      </p>

      <p className="descricao">
        A Reserva Particular do Patrimônio Natural - RPPN Alto Montana está
        inserida na Fazenda Pinhão Assado e protege um importante remanescente
        florestal, em área prioritária para a conservação da biodiversidade,
        abrigando inúmeras espécies da fauna e flora endêmicas e ameaçadas de
        extinção. Auxilia no amortecimento do Parque Nacional do Itatiaia e é
        responsável pela proteção de mais de 17 nascentes que abastecem a Bacia
        Hidrográfica do Rio Verde.
      </p>
      <p className="descricao">
        Dentro do eixo <b>Conservação da Biodiversidade</b> ficou definido que
        parte da Mata Atlântica preservada na propriedade deveria ser protegida
        por Lei, e então criou-se a <b>RPPN Alto Montana</b>, de 672ha em 27 de
        janeiro de 2012 por meio dos Decretos Municipais Nº 10.047 e 10.048.
        Para a criação desta Unidade de Conservação (UC) e elaboração de seu
        Plano de Manejo, o proprietário obteve apoio da SOS Mata Atlântica,
        através do Programa de Incentivo às RPPN da Mata Atlântica.
      </p>
      <p className="descricao">
        Considerando de grande importância a gestão socioambiental da Fazenda e
        da Reserva, foi criada uma pessoa jurídica, o{" "}
        <b>Instituto Alto Montana da Serra Fina</b>, uma associação civil de
        direito privado com fins não econômicos. Desde sua criação, o Instituto
        vem gerindo a RPPN através da articulação de parcerias com
        universidades, as quais têm garantido a produção de conhecimentos sobre
        os ecossistemas de montanha ainda pouco estudados no meio acadêmico,
        além de subsidiar a elaboração do plano de manejo da RPPN integrado com
        o plano de uso sustentável da Fazenda.
      </p>
      <div className="container-logos-fazenda">
        <img
          src={FazendaImg}
          alt="fazenda"
          style={{ width: "25%", objectFit: "contain" }}
        />
        <img
          src={RPPNImg}
          alt="RPPN"
          style={{ width: "25%", objectFit: "contain" }}
        />
      </div>
      <Box className="container-buttons">
        <Button
          target="blank"
          href="https://firebasestorage.googleapis.com/v0/b/instituto-altomontana.appspot.com/o/01%20-%20Plano%20de%20Manejo%20RPPN%20Alto%20Montana%20(1).pdf?alt=media&token=e234203b-f05c-491a-ab66-8a46bb884c91"
        >
          PLANO DE MANEJO
        </Button>
        <Button
          target="blank"
          href="https://firebasestorage.googleapis.com/v0/b/instituto-altomontana.appspot.com/o/Anexo%202_%20Mapa%20de%20Uso%20Publico%20(1).pdf?alt=media&token=665895f3-6820-44cf-945d-d2bb41a4de51"
        >
          MAPA DE USO PÚBLICO
        </Button>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <MapIframeBox />
      </Box>
    </Container>
  );
};

export default Fazenda;
