import { Container, MenuItem, Typography } from "@mui/material";
import logoImg from "../../assets/logo_footer.png";
import location from "../../assets/footer-icons/Footer_Endereco.png";
import fb from "../../assets/Footer_Facebook.png";
import linkedin from "../../assets/Footer_LinkedIn.png";
import ig from "../../assets/Footer_Instagram.png";
import quemSomos from "../../assets/footer-icons/Footer_QuemSomos.png";
import fazenda from "../../assets/footer-icons/Footer_FazendaPinhaoAssado.png";
import contato from "../../assets/footer-icons/Footer_Contato.png";
import pesquisa from "../../assets/footer-icons/Footer_PesquisaCientifica.png";
import edu from "../../assets/footer-icons/Footer_Educacao.png";
import producao from "../../assets/footer-icons/Footer_ProducaoAgroecologica.png";
import esporte from "../../assets/footer-icons/Footer_EsportesNaNatureza.png";
import turismo from "../../assets/footer-icons/Footer_TurismoDeMontanha.png";
import abrigo from "../../assets/footer-icons/Footer_AbrigoAltoMontana.png";
import coord from "../../assets/footer-icons/Footer_CoordenadasGeograficas.png";
import tel from "../../assets/footer-icons/Footer_ContatoTelefone.png";
import "./styles.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <Container className="footer-container">
        <div className="img-container">
          <img src={logoImg} alt="logo" />
        </div>
        <div
          style={{
            flex: 2.5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="container-top"
            style={{
              flex: 2.5,
              display: "flex",
              borderBottom: "1px solid white",
            }}
          >
            <div style={{ flex: 1 }}>
              <MenuItem component={Link} to="/quem-somos">
                <img
                  src={quemSomos}
                  alt="Ícone de localização"
                  style={{ marginRight: 4 }}
                />
                Quem Somos
              </MenuItem>
              <MenuItem component={Link} to="/fazenda-pinhao-assado">
                <img
                  src={fazenda}
                  alt="Ícone de localização"
                  style={{ marginRight: 4 }}
                />
                Fazenda Pinhão Assado <br />e RPPN Alto Montana
              </MenuItem>
              <MenuItem component={Link} to="/contato">
                <img
                  src={contato}
                  alt="Ícone de localização"
                  style={{ marginRight: 4 }}
                />
                Contato
              </MenuItem>
            </div>
            <div style={{ flex: 1 }}>
              <MenuItem component={Link} to="/pesquisa-cientifica">
                <img
                  src={pesquisa}
                  alt="Ícone de localização"
                  style={{ marginRight: 4 }}
                />
                Pesquisa Científica
              </MenuItem>
              <MenuItem component={Link} to="/educacao">
                <img
                  src={edu}
                  alt="Ícone de localização"
                  style={{ marginRight: 4 }}
                />
                Educação
              </MenuItem>
              <MenuItem component={Link} to="/producao-agroecologica">
                <img
                  src={producao}
                  alt="Ícone de localização"
                  style={{ marginRight: 4 }}
                />
                Produção Agroecológica
              </MenuItem>
              <MenuItem component={Link} to="/esportes-na-natureza">
                <img
                  src={esporte}
                  alt="Ícone de localização"
                  style={{ marginRight: 4 }}
                />
                Esportes na Natureza
              </MenuItem>
              <MenuItem component={Link} to="/turismo-de-montanha">
                <img
                  src={turismo}
                  alt="Ícone de localização"
                  style={{ marginRight: 4 }}
                />
                Turismo de Montanha
              </MenuItem>
              <MenuItem component={Link} to="/abrigo-alto-montana">
                <img
                  src={abrigo}
                  alt="Ícone de localização"
                  style={{ marginRight: 4 }}
                />
                Abrigo Alto Montana
              </MenuItem>
            </div>
            <div className="coordinates" style={{ flex: 1 }}>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 16,
                }}
              >
                <img
                  src={location}
                  alt="Ícone de localização"
                  style={{ marginRight: 4 }}
                />
                Endereço
              </span>
              <div
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  marginBottom: 15,
                  marginLeft: 22,
                }}
              >
                Rodovia Sebastião Alves do Nascimento - BR 354, km 768 - 769,{" "}
                <br />
                Bairro Engenho da Serra, Itamonte - MG
              </div>
              <div>
                <img
                  src={coord}
                  alt="Ícone de localização"
                  style={{ marginRight: 4 }}
                />
                Coordenadas geográficas <br />
                22° 21'09.5"S; 44º 47'45.9"W
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 28,
                }}
              >
                <a
                  href="https://www.instagram.com/instituto_altomontana/"
                  style={{ paddingLeft: 0 }}
                >
                  <img className="social" src={ig} alt="Ícone de localização" />
                </a>
                <a href="https://pt-br.facebook.com/InstitutoAltoMontanaOFICIAL/">
                  <img className="social" src={fb} alt="Ícone de localização" />
                </a>
                <a href="https://www.linkedin.com/company/institutoaltomontana/about/">
                  <img
                    className="social"
                    src={linkedin}
                    alt="Ícone de localização"
                  />
                </a>
              </div>
              {/* <span style={{ fontFamily: "Poppins", opacity: 0.7 }}>
              &copy; 2022 Instituto Alto Montana
            </span> */}
            </div>
          </div>
          <div
            className="container-bottom"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <MenuItem
              component="a"
              href="mailto:contato@institutoaltomontana.org"
            >
              contato@institutoaltomontana.org
            </MenuItem>
            <div
              className="tels"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <MenuItem
                style={{ display: "flex", flexDirection: "column" }}
                component="a"
                href="tel:+553599260-4484"
              >
                +55 (35) 99260-4484
                <p className="sub-tel">Informações e Agendamentos</p>
              </MenuItem>
              <MenuItem
                component="a"
                href="tel:+553599138-5593"
                style={{ display: "flex", flexDirection: "column" }}
              >
                +55 (35) 99138-5593
                <p className="sub-tel">RPPN Alto Montana</p>
                <p className="sub-tel">Gerência Uso Público</p>
              </MenuItem>
              <MenuItem
                component="a"
                href="tel:+553599122-4191"
                style={{ display: "flex", flexDirection: "column" }}
              >
                +55 (35) 99122-4191
                <p className="sub-tel">Instituto Alto Montana</p>
                <p className="sub-tel">Direção Executiva</p>
              </MenuItem>
            </div>
          </div>
        </div>
      </Container>
      {/* <h2><span>FARBTEC</span></h2> */}
    </footer>
  );
}

export default Footer;
