import React from "react";
import { Box, Button, Container } from "@mui/material";
import "../../globalStyles/styles.css";
import "./styles.css";
import passarosImg from "../../assets/pesquisa-passaros.png";

const Pesquisa = () => {
  return (
    <Container>
      <h1 className="titulo">Pesquisa Científica</h1>
      <hr />
      <p className="descricao">
        O Instituto Alto Montana da Serra Fina é a entidade gestora de área de
        1049 hectares de Floresta Atlântica de Altitude. Nossa área é composta
        pela Fazenda Pinhão Assado, Reserva Particular do Patrimônio Natural -
        RPPN Alto Montana e Abrigo Alto Montana, onde se desenvolvem trabalhos
        em 05 eixos vocacionais: Conservação da biodiversidade; Pesquisa e
        educação; Produção agroecológica; Turismo de montanha; e, Esportes na
        natureza.
      </p>

      <img src={passarosImg} alt="passaros" className="imgPesquisaPassaros" />
      <p className="descricao">
        Estamos localizados em Itamonte, sul do estado de Minas Gerais,
        município com um dos maiores índices de cobertura florestal remanescente
        do Bioma Mata Atlântica no Estado de Minas Gerais, o qual possui 80% de
        seu território inserido em áreas de 09 unidades de conservação (Parque
        Estadual Serra do Papagaio, Parque Nacional de Itatiaia, APA Serra da
        Mantiqueira, Monumento Natural da Pedra do Picú, RPPN Alto Montana, RPPN
        Sítio dois Irmãos, RPPN François Robert Arthur, RPPN Dalmunia, RPPN
        Fazenda Velha/ Verdever).{" "}
      </p>
      <p className="descricao">
        A conservação da biodiversidade é a função principal e transversal no
        Instituto Alto Montana, que conta com mais de 90% de sua área com
        cobertura vegetacional e incluindo nos 672 hectares da RPPN Alto Montana
        a proteção de inúmeras espécies de fauna e flora endêmicas e ameaçadas
        de extinção, bem como, 17 nascentes perenes que abastecem a Bacia
        Hidrográfica do Rio Verde.{" "}
      </p>
      <p className="descricao">
        As características de nossa reserva, tais como relevo altamente
        acidentado (com altitudes que variam entre 1400 a 2540 metros), somadas
        às especificidades de clima, solo, vegetação e fauna, já propiciaram a
        realização de mais de 80 trabalhos científicos em parceria com
        universidades estaduais e federais nas grandes áreas de Ciências da
        Natureza, Ecologia, Geologia, Hidrologia, Entomologia, Produções
        Energéticas Sustentáveis, Processos e Tecnologias de Produção
        Agroecológica, Levantamentos de Flora e Fauna, entre outras.{" "}
      </p>
      <p>
        Além dos aspectos naturais ressaltamos alguns diferenciais como
        localização geográfica estratégica (eixo Rio–SP), acesso ao instituto
        (totalmente pavimentado) e a áreas de pesquisa (estrada de terra que
        corta RPPN), estruturas para hospedagem no local (com tarifas
        diferenciadas para pesquisadores), cozinha compartilhada, auditório com
        40 lugares, sinal de telefonia móvel, internet WiFi. Estes trazem um
        conforto a mais para realização de atividades de pesquisa, ensino e/ou
        extensão.
      </p>
      <Box className="container-buttons">
        <Button
          target="blank"
          href="https://drive.google.com/drive/folders/1Z7vAU_MCNvYTSjW-FVvirZqWUhUticOp"
        >
          BANCO DE PESQUISAS CIENTÍFICAS PUBLICADAS NO IAM E ENTORNO
        </Button>
      </Box>
    </Container>
  );
};

export default Pesquisa;
