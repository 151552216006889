import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Button, SwipeableDrawer } from "@mui/material";

export const MenuItem = styled(Link)`
  display: flex;
  height: 64px;
  align-items: center;
  padding-inline: ${(props) => (props.menu ? "18px" : "15px")};
  color: ${(props) => (props.menu ? "white" : "black")};
  font-weight: ${(props) => (props.menu ? "400" : "500")};
  font-size: ${(props) => (props.menu ? "15px" : "14px")};
  text-decoration: none;
  text-align: center;
  /* margin-right: ${(props) => (props.menu ? "25px" : "5px")}; */
  &:hover {
    background-color: ${(props) => (props.menu ? "#64b5f6" : "#81c784")};
  }
  @media (max-width: 900px) {
    text-align: unset;
  }
`;

export const SwipeMenu = styled(SwipeableDrawer)`
  .MuiPaper-root {
    background-color: #466fc6;
  }
`;

export const ButtonAoVivo = styled(Button)`
  color: white;
  font-weight: 400;
  display: flex;
  min-height: 64px;
  border-radius: 0;
  &:hover {
    background-color: #64b5f6;
  }
`;
