import React from "react";
import { Container } from "@mui/material";
import "../../globalStyles/styles.css";
import "./styles.css";
import odsImg from "../../assets/ods-img.png";
import objetivo2 from "../../assets/objetivos/Objetivo_2.png";
import objetivo3 from "../../assets/objetivos/Objetivo_3.png";
import objetivo4 from "../../assets/objetivos/Objetivo_4.png";
import objetivo5 from "../../assets/objetivos/Objetivo_5.png";
import objetivo6 from "../../assets/objetivos/Objetivo_6.png";
import objetivo7 from "../../assets/objetivos/Objetivo_7.png";
import objetivo8 from "../../assets/objetivos/Objetivo_8.png";
import objetivo9 from "../../assets/objetivos/Objetivo_9.png";
import objetivo10 from "../../assets/objetivos/Objetivo_10.png";
import objetivo11 from "../../assets/objetivos/Objetivo_11.png";
import objetivo12 from "../../assets/objetivos/Objetivo_12.png";
import objetivo13 from "../../assets/objetivos/Objetivo_13.png";
import objetivo15 from "../../assets/objetivos/Objetivo_15.png";
import objetivo16 from "../../assets/objetivos/Objetivo_16.png";
import objetivo17 from "../../assets/objetivos/Objetivo_17.png";
import { Box } from "@mui/system";

const QuemSomos = () => {
  return (
    <>
      <Container>
        <h1 className="titulo">QUEM SOMOS</h1>
        <hr />

        <p className="descricao">
          Com mais de 1000 hectares de Floresta Atlântica de Altitude, a área do
          Instituto Alto Montana da Serra Fina é composta pela Fazenda Pinhão
          Assado, Reserva Particular do Patrimônio Natural - RPPN Alto Montana e
          Abrigo Alto Montana, onde se desenvolvem trabalhos em 5 eixos
          vocacionais: Conservação da biodiversidade, Pesquisa e educação,
          Produção agroecológica, Turismo de montanha, e Esportes na natureza.
        </p>
        <p className="descricao">
          Nossos principais objetivos são realizar a gestão da Fazenda Pinhão
          Assado e RPPN Alto Montana, bem como promover e apoiar projetos e
          atividades socioambientais nas Terras Altas da Mantiqueira.
        </p>
        <p className="descricao">
          Nossa missão é promover a conservação e preservação dos ecossistemas
          montanos da Serra da Mantiqueira por meio da gestão da RPPN Alto
          Montana e da Fazenda Pinhão Assado, fomentando a pesquisa, a educação,
          a formação de políticas públicas socioambientais e a adoção de
          práticas sustentáveis.
        </p>
        <p className="descricao">
          Nos dedicamos a ser uma organização atuante e reconhecida pela
          relevância e qualidade dos projetos desenvolvidos na Serra da
          Mantiqueira e alcançar nossa sustentabilidade econômica/financeira.
          Esse esforço reflete também em nosso engajamento com os Objetivos de
          Desenvolvimento Sustentável (ODS´s), propostos pela Organização das
          Nações Unidas (ONU), que visam a melhoria das condições de vida em
          nosso planeta até 2030.
        </p>
        <strong>
          Conheça nossas ações, engaje-se em nossos projetos, venha nos visitar!
        </strong>

        <a className="linkMapa" href="https://odsbrasil.gov.br/ “">
          <img
            src={odsImg}
            alt="Objetivos de Desenvolvimento Sustentável"
            className="odsImg"
          />
        </a>
      </Container>
      <Box className="objetivos">
        <img
          src={objetivo2}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo3}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo4}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo5}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo6}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo7}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo8}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo9}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo10}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo11}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo12}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo13}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo15}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo16}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
        <img
          src={objetivo17}
          alt="Lista de Objetivos de Desenvolvimento Sustentável"
          className="objetivosImg"
        />
      </Box>
    </>
  );
};

export default QuemSomos;
