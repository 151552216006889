import React from "react";
import { Container, Grid } from "@mui/material";
import "../../globalStyles/styles.css";
import "./styles.css";
import agro1 from "../../assets/agro1.png";
import agro2 from "../../assets/agro2.png";
import agro3 from "../../assets/agro3.png";
import agro4 from "../../assets/agro4.png";
import agro5 from "../../assets/agro5.png";
import agro6 from "../../assets/agro6.png";
import agro7 from "../../assets/agro7.png";

const Producao = () => {
  return (
    <Container>
      <h1 className="titulo">Producao Agroecológica</h1>
      <hr />
      <p className="descricao">
        A união da Ecologia com a Agricultura nos dá como resultado a
        <strong> Agroecologia</strong>, que tem como principal objetivo integrar
        as atividades agropecuárias com o meio ambiente. Assim, para diminuir os
        impactos negativos da nossa produção de alimentos sobre a qualidade da
        água, do solo e do ar, e, claro, também sobre as florestas e os animais,
        a Agroecologia usa - em atividades de cultivo e de criação de animais,
        por exemplo - princípios ecológicos para que tenhamos uma agricultura
        sustentável; ou seja, uma agricultura que possa garantir, para as
        gerações do presente e do futuro, uma alimentação saudável e uma
        convivência sustentável com a natureza, resultando em benefícios
        socioambientais e econômicos. Adaptado do texto de Maria Cristina Prata
        Neves.
      </p>
      <p className="descricao">
        Fonte:{" "}
        <a href="https://www.embrapa.br/agrobiologia" className="linkEmbrapa">
          Embrapa Agrobiologia
        </a>
      </p>

      <Grid
        container
        columnSpacing={{ xs: 2 }}
        style={{ justifyContent: "center", marginTop: 60, marginBottom: 60 }}
      >
        <Grid item xs={6} sm={4} md={2} lg={1.7}>
          <img src={agro1} alt="legumes e frutas" className="imgAgro" />
        </Grid>
        <Grid item xs={6} sm={4} md={2} lg={1.7}>
          <img src={agro2} alt="legumes e frutas" className="imgAgro" />
        </Grid>
        <Grid item xs={6} sm={4} md={2} lg={1.7}>
          <img src={agro3} alt="legumes e frutas" className="imgAgro" />
        </Grid>
        <Grid item xs={6} sm={4} md={2} lg={1.7}>
          <img src={agro4} alt="legumes e frutas" className="imgAgro" />
        </Grid>
        <Grid item xs={6} sm={4} md={2} lg={1.7}>
          <img src={agro5} alt="legumes e frutas" className="imgAgro" />
        </Grid>
        <Grid item xs={6} sm={4} md={2} lg={1.7}>
          <img src={agro6} alt="legumes e frutas" className="imgAgro" />
        </Grid>
        <Grid item xs={6} sm={4} md={2} lg={1.7}>
          <img src={agro7} alt="legumes e frutas" className="imgAgro" />
        </Grid>
      </Grid>

      <p className="descricao">
        Neste sentido, a produção de alimentos do Instituto Alto Montana da
        Serra Fina é realizada inteiramente nas bases agroecológicas e nas áreas
        da Fazenda Pinhão Assado. Temos produção de mel, morangos e frutas
        diversas, além de verduras e legumes agroecológicos.
      </p>

      <a
        className="linkMapa centerText feira"
        target="blank"
        href="https://api.whatsapp.com/send/?phone=5535992604484"
      >
        Consulte sobre nossa produção e nossa feira agroecológica.
      </a>
    </Container>
  );
};

export default Producao;
